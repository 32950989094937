<template>
    <section class="our-girls container">
        <div class="our-girls__tab">
            <div class="our-girls__tab__item" :class="{active: tabIndex === 1}" @click="tabIndex = 1">All Girl</div>
            <div class="our-girls__tab__item" :class="{active: tabIndex === 2}" @click="tabIndex = 2">Webcam</div>
            <div class="our-girls__tab__item" :class="{active: tabIndex === 3}" @click="tabIndex = 3">Online</div>
        </div>
        <div v-if="girls" class="our-girls__body">
            <transition-group name="fade">
                <div 
                    v-for="index in 3"
                    :key="index"
                    class="our-girls__list"
                    v-show="Number(tabIndex) === index"
                >
                    <div 
                        v-for="(item, itemIndex) in girls[index - 1]"
                        :key="itemIndex"
                        class="our-girls__item ta-center"
                        @click="$modal.show('join-modal')"
                    >
                        <div class="our-girls__item__img">
                            <img :src="applyImgSrc(item[1].photo)" :alt="item[1].name">
                        </div>
                        <div class="our-girls__item__info">
                            <div class="our-girls__item__title">{{ item[1].name }}</div>
                            <div class="our-girls__item__country">{{ item[1].country }}</div>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
        <Button 
            class="button"
            text="See all Girls"
            @eventClick="$modal.show('join-modal')"
        />
    </section>
</template>

<script>
import Button from '@/components/Base/Button';
export default {
    name: 'OurGirls',
    components: {
        Button,
    },
    data: () => ({
        girls: null,
        tabIndex: 1,
    }),
    created() {
        this.getGirls();
    },
    methods: {
        chunkArray(array, chunk) {
            const newArray = [];
            for (let i = 0; i < array.length; i += chunk) {
                newArray.push(array.slice(i, i + chunk));
            }
            return newArray;
        },
        async getGirls() {
            try {
                const query = await fetch('/api/?action=get_profiles', {
                    method: 'POST',
                });
                const resOut = await query.json();
                const entriesResOut = Object.entries(resOut);
                const arrays = [];
                while (entriesResOut.length > 0) 
                    arrays.push(entriesResOut.splice(0, 8));

                this.girls = arrays;
            } catch(error) {
                console.error(error);
            }
        },
        applyImgSrc(url) {
			return '//storage.victoriyaclub.com' + url;
		},
        onSlideChange() {
            const activeIndex = this.$refs.swiper.$swiper.activeIndex;
            document.querySelectorAll('.swiper-slide').forEach((item, index) => {
                if (index >= activeIndex - 2 && index <= activeIndex + 2) {
                    item.classList.add('visible');
                } else {
                    item.classList.remove('visible');
                }
            });
        }
    }
}
</script>

<style lang="scss">

    .our-girls {
        position: relative;

        &__tab {
            display: flex;
            justify-content: center;
            width: fit-content;
            height: 60px;
            background: $white;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.06);
            border-radius: 15px;
            font-weight: 600;
            font-size: 18px;
            line-height: 60px;
            
            @include xs {
                margin: 0 auto 50px auto;
            }

            @include sm {
                margin: 0 auto 58px auto;
            }

            @include md {
                margin: 0 auto 58px auto;
            }

            @include lg {
                margin: 0 auto 39px auto;
            }

            &__item {
                height: 100%;
                padding: 0 23px;
                transition: 250ms ease-in-out;
                border-radius: 15px;
                cursor: pointer;

                &:hover {
                    color: $pink;
                }

                &.active {
                    background: $pink;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
                    color: $white !important;
                    user-select: none;
                    pointer-events: none;
                    touch-action: none;
                }
            }
        }

        &__body {

            span {
                display: block;

                @include xs {
                    height: 750px;
                    margin-bottom: 62px;
                }
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media (max-width: 1439px) {
                width: calc(100% + 20px);
                margin: 0 -10px;
            }

            @include lg {
                width: calc(100% + 30px);
                margin: 0 -15px;
            }
        }

        &__item {
            position: relative;

            @include xs {
                max-width: 163px;
                width: calc(50% - 20px);
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 58px;

                &:nth-child(1n + 5) {
                    display: none;
                }
            }

            @include sm {
                width: 208px;
                margin-bottom: 72px;

                &:nth-child(1n + 7) {
                    display: none;
                }
            }

            @include md {
                width: 208px;
                margin-bottom: 72px;

                &:nth-child(1n + 9) {
                    display: none;
                }
            }

            @include lg {
                width: 270px;
                margin-bottom: 68px;
            }

            &__img {
                position: relative;
                width: 100%;
                border-radius: 20px;
                overflow: hidden;

                @include xs {
                    height: 273px;
                }

                @include sm {
                    height: 360px;
                }

                @include md {
                    height: 360px;
                }

                @include lg {
                    height: 405px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center top;
                }

                &::before {
                    content: 'online';
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 4px 13px;
                    width: 78px;
                    height: 30px;
                    line-height: 30px;
                    background: #15AB48;
                    border-radius: 0px 5px 5px 0px;
                    font-size: 14px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }

            &__title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 30px;
                line-height: 38px;
                font-weight: 700;

                @include xs {
                    margin: 10px 0 0 0;
                }

                @include sm {
                    margin: 14px 0 0 0;
                }

                @include md {
                    margin: 10px 0 0 0;
                }

                @include lg {
                    margin: 10px 0 0 0;
                }
            }

            &__country {
                color: $dark;
            }
        }

        .button {
            width: 267px;
            margin: 0 auto;

            @include md {
                margin-top: 42px;
            }

            @include lg {
                margin-top: -7px;
            }
        }
    }
</style>