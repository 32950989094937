<template>
    <section class="why-us container">
        <h2 class="title title--h2 ta-center">Who we are!</h2>
        <div class="text ta-center">
            <p>We are is one of the best international dating websites, which is available 24/7, and has been in business since 2011. We started our website because we believe that everyone should have someone that they can love and can be loved by.</p>
        </div>

        <div class="why-us__list">
            <div 
                v-for="(item, index) in list"
                :key="index"
                class="why-us__item"
                @click="$modal.show('join-modal')"
            >
                <img :src="item.icon" :alt="item.title">
                <div class="why-us__item__title">{{ item.title }}</div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'WhyUs',
    data: () => ({
        list: [
            {
                icon: require('@/assets/protection.svg'),
                title: 'You register in the club',
            }, {
                icon: require('@/assets/verification.svg'),
                title: 'Using the filter looking for a girl',
            }, {
                icon: require('@/assets/communication.svg'),
                title: 'Enjoy your communication',
            }
        ]
    })
}
</script>

<style lang="scss">
    .why-us {
        position: relative;

        @include xs {
            margin-top: 92px;
            margin-bottom: 27px;
        }

        @include sm {
            margin-top: 93px;
            margin-bottom: 93px;
        }

        @include md {
            margin: 93px auto 142px auto;
        }

        @include lg {
            margin: 93px auto 142px auto;
        }

        .text {

            @media (min-width: 768px) {
                width: 100%;
                max-width: 618px;
                margin: 0 auto;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;

            @include xs {
                margin-top: 45px;
                padding: 0 10px;
            }

            @include sm {
                margin-top: 50px;
                width: 768px;
                transform: translateX(-50px);
            }

            @include md {
                margin-top: 50px;
            }

            @include lg {
                margin-top: 50px;
            }
        }

        &__item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: 250ms ease-in-out;
            border-radius: 15px;
            cursor: pointer;

            @media (min-width: 1024px) {
                &:hover {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
                }
            }

            &:active {
                background: #FFFFFF;
                box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
            }

            @include xs {
                justify-content: center;

                &:nth-child(2) {
                    order: 1;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
                }

                &:nth-child(1) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 3;
                }

                width: 100%;
                margin-bottom: 20px;
                padding: 46px 56px;
            }

            @include sm {
                &:nth-child(2) {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
                }

                width: calc(100% / 3 - 20px);
                margin-left: 10px;
                margin-right: 10px;
                padding: 46px 34px;
            }

            @include md {
                &:nth-child(2) {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
                }

                width: calc(100% / 3 - 20px);
                margin-left: 10px;
                margin-right: 10px;
                padding: 46px 34px;
            }

            @include lg {
                &:nth-child(2) {
                    background: #FFFFFF;
                    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.04);
                }
                width: calc(100% / 3 - 20px);
                max-width: 370px;
                margin-left: 10px;
                margin-right: 10px;
                padding: 56px 85px 51px 85px;
            }


            img {
                width: 80px;

                @include xs {
                    margin-bottom: 14px;
                }

                @include sm {
                    margin-bottom: 20px;
                }

                @include md {
                    margin-bottom: 20px;
                }

                @include lg {
                    margin-bottom: 20px;
                }
            }

            &__title {
                font-weight: 600;
                text-align: center;

                @include xs {
                    font-size: 22px;
                }

                @include sm {
                    font-size: 22px;
                }

                @include md {
                    font-size: 24px;
                    line-height: 36px;
                }

                @include lg {
                    font-size: 24px;
                    line-height: 36px;
                }
            }
        }
    }
</style>