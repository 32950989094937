<template>
	<div id="app">

		<router-view />

		<Preloader class="preloader-general" :showHide="$root.isPreloadGeneral"/>

	</div>
</template>

<script>

import Preloader from '@/components/Base/Preloader.vue';

export default {
	components: {
		Preloader
	}
}
</script>


<style lang="scss">
	@import '@/scss/reset';
	@import '@/scss/typography';
	@import '@/scss/form';

	html {
		font-size: 16px;
		font-family: 'Source Sans Pro', sans-serif;
		color: $black;
		overflow-x: hidden;
	}

	body {
		width: 100%;
		line-height: 1.5;
		background-color: $gray;
	}

	.container {
		width: 100%;
		padding: 0 20px;

		@include xs {
			max-width: 375px;
			margin: 0 auto;
		}

		@include sm {
			max-width: 708px;
			margin: 0 auto;
		}

		@include md {
			max-width: 932px;
			margin: 0 auto;
		}

		@include lg {
			max-width: 1190px;
			margin: 0 auto;
		}
	}
</style>