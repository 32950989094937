<template>
    <section class="info">
        <Registration className="info__form">
            <h2 class="title title--h1 ta-center">
                JOIN OUR CLUB
            </h2>
        </Registration>
        <picture class="info__background">
            <source media="(min-width:1440px)" :srcset="require('@/assets/info-1440.png')">
            <source media="(min-width:992px)" :srcset="require('@/assets/info-992.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/info-768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/info-320.png')">
            <img :src="require('@/assets/info-320.png')" alt="Real Stories">
        </picture>
    </section>
</template>

<script>
import Registration from '@/components/Registration';

export default {
    name: 'Info',
    components: {
        Registration
    },
    data: () => ({
        
    }),
    methods: {
    }
}
</script>

<style lang="scss">
    .info {
        position: relative;

        @media (min-width: 992px) {
            &::before {
                content: '';
                z-index: -1;
                position: absolute;
                top: 0;
                left: 50%;
                width: 100vw;
                height: 100%;
                transform: translateX(-50%);
                background-color: $white;
            }
        }

        @include sm {
            padding-top: 277px;
        }

        @include md {
            display: flex;
			max-width: 932px;
			margin: 0 auto;
            justify-content: space-between;
        }

        @include lg {
            display: flex;
			max-width: 1190px;
			margin: 0 auto;
            justify-content: space-between;
        }

        &__background {

            @media (max-width: 991px) {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;

                box-shadow: 2px 2px 40px 2px rgba(0, 0, 0, 0.08);
                border-radius: 20px;
                overflow: hidden;
            }

            @include sm {
                max-width: 708px;
                left: 50%;
                transform: translateX(-50%);
            }


            @include md {
                position: relative;

                &::before {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    left: -50px;
                    width: 50vw;
                    height: 100%;
                    background: linear-gradient(245.43deg, #FFFFFF 37.12%, #F2F2F2 86.65%);
                    border-radius: 20px;
                }
            }

            @include lg {
                position: relative;
                padding-bottom: 36px;

                &::before {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    top: -50px;
                    left: -60px;
                    width: calc(100% + 120px);
                    height: 100%;
                    background: linear-gradient(245.43deg, #FFFFFF 37.12%, #F2F2F2 86.65%);
                    border-radius: 20px;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center top;
                border-radius: 20px;

                @include md {
                    margin-top: -34px;
                    box-shadow: 2px 2px 40px 2px rgba(0, 0, 0, 0.08);
                }

                @include lg {
                    box-shadow: 2px 2px 40px 2px rgba(0, 0, 0, 0.08);
                    border-radius: 20px;
                }
            }
        }

        &__form {
            position: relative;
            z-index: 2;

            @include xs {
                max-width: 375px;
                margin: 0 auto;
                padding-left: 20px;
                padding-right: 20px;
            }

            @include sm {
                position: relative;
                max-width: 454px;
                margin: 0 auto;
            }

            @include md {
                position: relative;
                max-width: 420px;
                width: 100%;
                margin: 0 auto;
                padding-right: 50px;
            }
            
            @media (max-width: 991px) {
                &::after {
                    content: '';
                    z-index: -1;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    width: 100vw;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.5);
                    backdrop-filter: blur(5px);
                    transform: translateX(-50%);
                }
            }

            @include lg {
                position: relative;
                max-width: 440px;
                width: 100%;
                margin: auto;
                padding-right: 70px;

                .title {
                    text-align: center;
                }
            }
            padding: 76px 0 82px;
        }
    }
</style>