<template>
    <footer class="footer">
        <div class="footer__wrapper">
            <div class="footer__payments">
                <img 
                    v-for="(payment, index) in payments"
                    :key="index"
                    :src="payment"
                    alt="#"
                >
            </div>
            <div class="footer__copyright">
                ©LoveTime2016-{{ (new Date()).getFullYear() }}
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data: () => ({
        payments: [
            require('@/assets/p-1.png'),
            require('@/assets/p-2.png'),
            require('@/assets/p-3.png'),
            require('@/assets/p-4.png')
        ]
    })
}
</script>

<style lang="scss">
    .footer {
        position: relative;
        overflow: hidden;

        @include xs {
            padding: 23px 20px 7px 20px;
        }

        @media (min-width: 768px) and (max-width: 1439px) {
            padding: 27px 0 28px 0;
        }

        @media (min-width: 1440px) {
            padding: 27px 0 28px 0;
        }

        &__wrapper {
            width: 100%;
            display: flex;
            align-items: center;

            @include xs {
                flex-direction: column;
                justify-content: center;
            }

            @include sm {
                max-width: 708px;
			    margin: 0 auto;
            }

            @media (min-width: 768px) {
                justify-content: space-between;
                margin: 0 auto;
            }

            @include md {
                max-width: 882px;
            }

            @media (min-width: 1440px) {
                max-width: 822px;
                margin: 0 auto;
            }
        }

        &__payments {
            z-index: 3;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @include xs {
                margin: 0 -12.5px 20px -12.5px;
                width: calc(100% + 25px);
            }

            @media (min-width: 768px) {
                width: 50%;
                flex-shrink: 0;
            }

            @include lg {
                margin: 0 -30px;
            }

            img {
                max-width: 90px;
                height: fit-content;

                @include xs {
                    margin: 0 12.5px;
                    width: calc(25% - 25px);
                }

                @media (min-width: 768px) {
                    margin: 0 12.5px;
                    width: calc(25% - 25px);
                }

                @include lg {
                    margin: 0 30px;
                    width: calc(25% - 30px);
                }
            }
        }

        &__copyright {
            flex-shrink: 0;
            z-index: 3;
            position: relative;
            display: flex;
            align-items: center;
            color: #ABB0C0;
            margin-left: auto;

            @include xs {
                margin: 0 auto;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
</style>