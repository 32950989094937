<template>
  <button
    @click.prevent="$emit('eventClick')"
    :type="type"
    class="button"
    :disabled="disabled"
    :class="classNames"
  >
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    classNames: String,
    disabled: [String, Boolean],
    type: {
      type: String,
      default: 'button'
    }
  },
  methods: {
    eventclick() {
      this.$emit('eventclick');
    }
  }
};
</script>

<style lang="scss">
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 58px;
    padding: 12px 20px 13px 20px;
    border: none;
    background: $pink;
    border-radius: 15px;
    box-shadow: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: $white;
    transition: 150ms ease-in-out;
    text-decoration: none;
    cursor: pointer;

    @media (min-width: 1024px) {
      &:hover {
        background-color: $pink1;
      }
    }

    &:active {
      background-color: #DB0C6C;
    }

    img {
      margin-left: 12px;
    }
  }
</style>