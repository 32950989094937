<template>
    <header class="header">
        <picture class="header__background">
            <source media="(min-width:1440px)" :srcset="require('@/assets/bg_1440.png')">
            <source media="(min-width:992px)" :srcset="require('@/assets/bg_992.png')">
            <source media="(min-width:768px)" :srcset="require('@/assets/bg_768.png')">
            <source media="(min-width:320px)" :srcset="require('@/assets/bg_320.png')">
            <img :src="require('@/assets/bg_1440.png')" alt="Easy Dating">
        </picture>
        <div class="container">
        <img :src="require('@/assets/girl.png')" alt="Easy Dating" class="header__girl">
            <img class="header__logotype" :src="require('@/assets/logo.svg')" alt="Easy Dating" />
            <h1 class="header__text">
                <div class="header__text__top">Looking for</div>
                <div class="header__text__middle">a dream</div>
                <div class="header__text__bottom">
                    <img :src="require('@/assets/title.svg')" alt="Easy Dating">
                </div>

                <Button 
                    class="button"
                    text="Create an account"
                    @eventClick="$modal.show('join-modal')"
                />
            </h1>
        </div>
    </header>
</template>

<script>
import Button from '@/components/Base/Button';

export default {
    name: 'Header',
    components: {
        Button,
    }
}
</script>

<style lang="scss">
    .header {
        position: relative;

        .container {
            position: relative;
            z-index: 2;
        }

        &__girl {

            @media (max-width: 1439px) {
                display: none;
            }
            
            @include lg {
                z-index: -1;
                position: absolute;
                height: 100%;
                width: auto;
                max-width: unset;
                right: -80px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__background {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                background: linear-gradient(180deg, rgba(242, 242, 242, 0) 5.69%, #F2F2F2 42.29%);

                @include xs {
                    height: 461px;
                    bottom: 0;
                    background: linear-gradient(178.73deg, rgba(242, 242, 242, 0) -51.33%, #F2F2F2 30.49%);
                }

                @include sm {
                    bottom: 0px;
                    height: 467px;
                    background: linear-gradient(180deg, rgba(242, 242, 242, 0) 13.06%, #F2F2F2 26.89%);
                }

                @include md {
                    bottom: -122px;
                    height: 336px;
                }

                @include lg {
                    bottom: -143px;
                    height: 340px;
                }
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: left bottom;

                @include xs {
                    object-fit: unset;
                    object-position: top center;
                    height: auto;
                    max-width: 100%;
                }

                @include sm {
                    object-fit: unset;
                    object-position: top center;
                    height: auto;
                    max-width: 100%;
                }

                @include md {
                    object-position: left 30%;
                }

                @include lg {
                    object-position: left 30%;
                }
            }
        }

        &__logotype {
            height: auto;

            @include xs {
                width: 85px;
                margin: 24px 0 369px 0;
            }

            @include sm {
                width: 111px;
                margin: 62px 0 399px 0;
            }

            @include md {
                width: 111px;
                margin: 62px 0 111px 0;
            }

            @include lg {
                width: 111px;
                margin: 53px 0 102px 0;
            }
        }

        &__text {
            color: #2D3535;
            text-transform: uppercase;
            width: 100%;

            @include xs {
                padding-bottom: 103px;
            }

            @include sm {
                max-width: 703px;
                padding: 0 20px;
                margin: 0 auto;
                padding-bottom: 78px;

                .button {
                    max-width: 579px;
                    margin: 0 auto;
                }
            }

            @include md {
                max-width: 444px;
                padding-bottom: 78px;
            }

            @include lg {
                max-width: 470px;
                padding-bottom: 176px;
            }
            
            &__top {
                letter-spacing: 0.2em;

                @include xs {
                    text-align: center;
                    font-size: 30px;
                    line-height: 38px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: #282222;
                    text-shadow: 0px 7px 15px rgba(255, 255, 255, 0.76);
                }

                @include sm {
                    text-align: center;
                    font-size: 55px;
                    line-height: 69px;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: #282222;
                    text-shadow: 0px 7px 15px rgba(255, 255, 255, 0.76);
                }

                @include md {
                    font-size: 34px;
                    line-height: 43px;
                }

                @include lg {
                    font-size: 37px;
                    line-height: 47px; 
                }
            }

            &__middle {
                font-weight: 700;
                text-transform: uppercase;
                text-shadow: 0px 5px 100px #FFFFFF;

                @include xs {
                    margin: -15px 0 -5px 0;
                    text-align: center;
                    letter-spacing: -0.03em;
                }

                @media (max-width: 374px) {
                    font-size: 64px;
                }

                @media (min-width: 375px) and (max-width: 767px) {
                    font-size: 89px;
                    line-height: 112px;
                }

                @include sm {
                    margin: -50px 0 -20px 0;
                    text-align: center;
                    font-size: 161px;
                    line-height: 202px;
                    letter-spacing: -0.03em
                }

                @include md {
                    margin: -20px 0 -10px 0;
                    font-size: 119px;
                    line-height: 150px;
                    letter-spacing: -0.03em;
                }

                @include lg {      
                    margin-top: -5px;
                    font-size: 126px;
                    line-height: 158px;
                    letter-spacing: -0.03em;
                }
            }

            &__bottom {
                @include xs {
                    margin-bottom: 38px;
                }

                @include sm {
                    margin-bottom: 50px;
                }

                @include md {
                    margin-bottom: 40px;
                }

                @include lg {
                    margin-bottom: 57px;
                }

                img {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
</style>